export const MAX_ZOOM = 8;

export const legend = [
  { value: 4, color: "#58b453" },
  { value: 6, color: "#97d265" },
  { value: 11, color: "#c4e687" },
  { value: 21, color: "#ecf7ad" },
  { value: 36, color: "#ffedab" },
  { value: 51, color: "#fec981" },
  { value: 91, color: "#f99e59" },
  { value: 400, color: "#e85b3a" },
  { value: 600, color: "#d7191c" }
];

export const style = table => ({
  "version": 8,
  "sources": {
    "country-boundaries": {
      "type": "vector",
      "url": "mapbox://mapbox.country-boundaries-v1?optimize=true"
    },
    "postgis-tiles": {
      "type": "vector",
      "tiles": [
        `${process.env.REACT_APP_DENSITY_API}/${table}/{z}/{x}/{y}.mvt`
      ]
    }
  },
  "layers": [
    {
      "id": "country-boundaries-layer",
      "source": "country-boundaries",
      "source-layer": "country_boundaries",
      "type": "fill",
      "maxzoom": MAX_ZOOM,
      "paint": {
        "fill-color": "#272727",
        "fill-outline-color": "#878787"
      }
    },
    {
      "id": "postgis-tiles-layer",
      "source": "postgis-tiles",
      "source-layer": "default",
      "type": "fill",
      "maxzoom": MAX_ZOOM,
      "paint": {
        "fill-color": [
          "step",
          // "interpolate",
          // ["linear"],
          ["get", "density_tier"],
          // 1
          legend[0].color,
          2,
          legend[1].color,
          3,
          legend[2].color,
          4,
          legend[3].color,
          5,
          legend[4].color,
          6,
          legend[5].color,
          7,
          legend[6].color,
          8,
          legend[7].color,
          9,
          legend[8].color,
        ]
        // "fill-color": [
        //   "step",
        //   // "interpolate",
        //   // ["linear"],
        //   ["get", "count"],
        //   // 1,
        //   "transparent",
        //   4,
        //   "#58b453",
        //   6,
        //   "#97d265",
        //   11,
        //   "#c4e687",
        //   21,
        //   "#ecf7ad",
        //   36,
        //   "#ffedab",
        //   51,
        //   "#fec981",
        //   91,
        //   "#f99e59",
        //   400,
        //   "#e85b3a",
        //   600,
        //   "#d7191c"
        // ]
      }
    }
  ]
});
