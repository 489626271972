import { useEffect } from "react"
import { useAuth } from "react-oidc-context";

const AuthRoute = ({ children }) => {
    
  const auth = useAuth();

  // Automatically sign-in
  useEffect(() => {
    if (!auth.isAuthenticated && !auth.isLoading) { auth.signinRedirect() }
  }, [auth]);

  return auth.isAuthenticated ? children : (null)
}

export default AuthRoute
